<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ul
  *ngIf="breakpointMatch"
  class="relative pt-4 text-xs text-theme-white z-base md:pt-20">
  <li *ngFor="let item of navItems">
    <app-layout-nav-link
      *ngIf="item.shape === '_COMMON'"
      [iconPath]="item.icon"
      [iconLabel]="item.iconLabel"
      [location]="item.url"
      [role]="groupType"
      [incomming_buyedPackages]="incomming_buyedPackages"
      [treatment_buyedPackages]="treatment_buyedPackages"
      [finished_buyedPackages]="finished_buyedPackages"
      [contacts]="contacts"
      [unuseBuyedPackages]="unuseBuyedPackages"
      [count_records]="count_records"
      [isLoading]="isLoading">
      {{ item.name }}
    </app-layout-nav-link>
    <app-layout-external-nav-link
      *ngIf="item.shape === '_EXTERNAL'"
      [iconPath]="item.icon"
      [iconLabel]="item.iconLabel"
      [location]="item.url">
      {{ item.name }}
    </app-layout-external-nav-link>
  </li>
  <li *ngIf="groupType === role.Kimbo">
    <a
      (click)="openSupportModal()"
      class="focus:outline-none focus:shadow-inner-lighter-blue w-full cursor-pointer focus:text-blue flex justify-center flex-col items-center gap-1 text-center py-4 relative">
      <img appImg class="w-6 h-6" [src]="'assets/svg/faq.svg'" alt="faq" />
      <span class="tab tab-home block text-xs">{{
        'kimbo-layout.nav-support-link' | translate
      }}</span>
    </a>
  </li>
</ul>

<div *ngIf="!breakpointMatch" id="tabs" class="flex justify-between">
  <a
    *ngFor="let item of navItems; let first = first; let last = last"
    [routerLink]="[item.url]"
    [state]="{ state: { filterPatient: false, dashBoardRedirection: false } }"
    routerLinkActive="active-link"
    [routerLinkActiveOptions]="{ exact: true }"
    class="focus:outline-none w-full flex justify-center flex-col items-center gap-1 text-center px-2 pt-2 pb-2 relative bg-opacity-30"
    [ngClass]="{
      'rounded-tl-[30px]': first,
      '': last && groupType !== role.Companie
    }">
    <div
      *ngIf="
        (groupType === role.Hcp && item.url !== '/hcp/dashboard-hcp') ||
        (!services.sessionService.isGuest() &&
          groupType === role.Kimbo &&
          item.url === '/kimbo/my-beneficiary')
      "
      class="absolute bg-blue top-1 right-3 z-base w-5 h-5 text-sm items-center justify-center flex rounded-full text-white font-semibold pointer-events-none cursor-default">
      <div
        *ngIf="isLoading"
        class="w-full flex items-center justify-center h-full">
        <app-spinner
          class="inline-block h-full w-full filter-white"></app-spinner>
      </div>
      <span *ngIf="!isLoading && item.url === '/kimbo/my-beneficiary'">{{
        contacts
      }}</span>
      <span *ngIf="!isLoading && item.url === '/hcp'">{{
        incomming_buyedPackages
      }}</span>
      <span *ngIf="!isLoading && item.url === '/hcp/patient-in-treatment'">{{
        treatment_buyedPackages
      }}</span>
      <span *ngIf="!isLoading && item.url === '/hcp/patient-treated'">{{
        finished_buyedPackages
      }}</span>
      <span *ngIf="!isLoading && item.url === '/hcp/campaigns'">{{
        unuseBuyedPackages
      }}</span>
    </div>
    <img appImg class="w-6 h-6 filter-blue" [src]="item.icon" alt="icon" />
    <span class="tab tab-home block text-xs">{{
      groupType !== role.Admin ? item.mobileName : item.name
    }}</span>
  </a>
  <a
    *ngIf="groupType === role.Companie"
    (click)="openCampainSnack()"
    class="w-full flex items-center py-4 px-6 justify-between focus:text-blue focus:outline-none focus:shadow-inner-lighter-blue md:text-center md:px-2 md:flex-col relative cursor-pointer z-navigation">
    <div class="w-full flex items-center gap-1 justify-between">
      <img
        appImg
        src="assets/images/human.png"
        class="relative top-0 w-7 md:w-6"
        alt="human" />
      <div *ngIf="isLoading" class="flex items-center justify-center h-fit">
        <app-spinner
          class="inline-block ml-4 h-6 w-6 filter-blue"></app-spinner>
      </div>
      <a
        *ngIf="!isLoading && !currentCampaign"
        [routerLink]="['/corporate/onboarding']"
        class="focus:outline-none focus:shadow-inner-lighter-blue w-full active:active-tabs focus:text-blue text-blue flex justify-center flex-col items-center gap-1 text-center pt-2 pb-2 relative">
        <span class="tab tab-home block text-xs">{{
          'corporate-dashboard.new-campaign-label' | translate
        }}</span>
      </a>
      <span
        *ngIf="!isLoading && currentCampaign"
        class="overflow-hidden whitespace-nowrap overflow-ellipsis"
        >{{ currentCampaign.name }}</span
      >
      <img
        appImg
        *ngIf="!isLoading && currentCampaign"
        aria-label="chevron icon"
        class="flex items-center w-6 filter-blue {{
          isCSnackOpen ? 'rotate-180' : ''
        }}"
        src="assets/svg/chevron-up.svg"
        alt="checvron up" />
      <img
        appImg
        *ngIf="!isLoading && !currentCampaign"
        aria-label="plus icon"
        class="flex items-center w-6 {{ isCSnackOpen ? 'rotate-180' : '' }}"
        src="assets/svg/plus.svg"
        alt="plus" />
    </div>
  </a>
  <a
    (click)="openSideMenu()"
    class="focus:outline-none focus:shadow-inner-lighter-blue {{
      groupType === role.Companie ? 'w-fit px-5' : 'w-full px-0'
    }}  focus:text-blue flex justify-center bg-kimbo-blue flex-col items-center rounded-tr-[30px] gap-1 text-center p-1 relative cursor-pointer">
    <i
      *ngIf="services.sessionService.isGuest()"
      class="w-12 text-white ri-menu-line"></i>

    <app-avatar
      *ngIf="
        !services.sessionService.isGuest() &&
        this.services.sessionService.getUser()
      "
      class="h-10 w-10 cursor-pointer"
      [classNames]="'h-10 w-10'"
      [withFrame]="false"
      [withPreview]="false"
      [src]="this.services.sessionService.getUser().image_profil | imageToUrl"
      caller="_PROFILE"></app-avatar>
    <span class="tab tab-home block text-xs text-white"> Menu </span>
  </a>
</div>
